import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Pages
const Home = React.lazy(() => import("./components/pages/Home"));
const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
const About = React.lazy(() => import("./components/pages/About"));
const Blog = React.lazy(() => import("./components/pages/Blog"));
const Blogleft = React.lazy(() => import("./components/pages/Blogleft"));
const Blogdetail = React.lazy(() => import("./components/pages/Blogdetail"));
const Service = React.lazy(() => import("./components/pages/Service"));
const Servicedetail = React.lazy(() => import("./components/pages/Servicedetail"));
const Loancalculator = React.lazy(() => import("./components/pages/Loancalculator"));
const Loanstep = React.lazy(() => import("./components/pages/Loanstep"));
const Team = React.lazy(() => import("./components/pages/Team"));
const Finance = React.lazy(() => import("./components/pages/Finance"));
const Comingsoon = React.lazy(() => import("./components/pages/Comingsoon"));
const Error = React.lazy(() => import("./components/pages/Error"));
const Contact = React.lazy(() => import("./components/pages/Contact"));
const Userdashboard = React.lazy(() => import("./components/pages/Userdashboard"));

function App() {
  return (
    <div className="main-body">
      <Router basename={'/'}>
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/features" component={Hometwo} />
            <Route exact path="/about" component={About} />
            <Route exact path="/blog" component={Blog} /> 
            <Route exact path="/blog-sidebar" component={Blogleft} />
            <Route exact path="/blog-single/:id" component={Blogdetail} />
            <Route exact path="/service" component={Service} />
            <Route exact path="/service-detail/:id" component={Servicedetail} />
            <Route exact path="/loan-calculator" component={Loancalculator} />
            <Route exact path="/loan-step" component={Loanstep} />
            <Route exact path="/team" component={Team} />
            <Route exact path="/finance" component={Finance} />
            <Route exact path="/coming-soon" component={Comingsoon} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/loan-dashboard" component={Userdashboard} />
            <Route exact path="/error" component={Error} />
            <Route exact component={Error} />
          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
